import React from 'react';

const SocialsMobile = () => {
  return (
    <div className='socialsMobile__wrapper'>
      <div className='socialsMobile__item'>
        <a
          className='socialsMobile__link'
          href='https://www.facebook.com/p/%C5%81owisko-Barcin-100066480673142/'
        >
          <i class='fa-brands fa-facebook-f'></i>
        </a>
      </div>
      <div className='socialsMobile__item'>
        <a
          href='https://www.instagram.com/lowisko_barcin/'
          className='socialsMobile__link'
        >
          <i class='fa-brands fa-instagram'></i>
        </a>
      </div>
      <div className='socialsMobile__item'>
        <a
          href='https://www.tiktok.com/@lowisko_barcin'
          className='socialsMobile__link'
        >
          <i class='fa-brands fa-tiktok'></i>
        </a>
      </div>
      <div className='socialsMobile__item'>
        <a href='tel:+48501515463' className='socialsMobile__link'>
          <i class='fa-solid fa-phone'></i>
        </a>
      </div>
    </div>
  );
};

export default SocialsMobile;

// O
