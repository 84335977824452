import React from 'react';

const Socials = () => {
  return (
    <div className='socials__wrapper'>
      <div className='socials__item'>
        <a
          className='socials__link'
          href='https://www.facebook.com/p/%C5%81owisko-Barcin-100066480673142/'
        >
          <i class='fa-brands fa-facebook-f'></i>
        </a>
      </div>
      <div className='socials__item'>
        <a
          className='socials__link'
          href='https://www.instagram.com/lowisko_barcin/'
        >
          <i class='fa-brands fa-instagram'></i>
        </a>
      </div>
      <div className='socials__item'>
        <a
          className='socials__link'
          href='https://www.tiktok.com/@lowisko_barcin'
        >
          <i class='fa-brands fa-tiktok'></i>
        </a>
      </div>
      <div className='socials__item'></div>
    </div>
  );
};

export default Socials;
