import React from 'react';

import DesktopNavList from './DesktopNavList';
import ImageLogo from './ImageLogo';
import Socials from '../socials/Socials';

const DesktopNav = ({ handleScroll }) => {
  return (
    <>
      <div className='navDesktop__logo'>
        <ImageLogo />
      </div>
      <DesktopNavList handleScroll={handleScroll} />
      <Socials />
    </>
  );
};

export default DesktopNav;
