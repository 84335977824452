import React from 'react';
import { NavLink } from 'react-router-dom';
import SocialsMobile from '../socials/SocialsMobile';

const MobileNavList = ({ handleClick, handleScroll }) => {
  const elementsNav = [
    { name: 'home', path: '/' },
    { name: 'zawody', path: '/zawody' },
    { name: 'Puchar Łowiska', path: '/wynikiPucharŁowiska' },
    { name: 'regulamin', path: '/regulamin' },
    { name: 'galeria', path: '/galeria' },
    { name: 'youtube', path: '/youtube' },
  ];

  const navElements = elementsNav.map((element) => {
    // Sprawdź, czy element jest jednym z linków społecznościowych
    const isSocialLink = ['facebook', 'instagram', 'tiktok'].includes(
      element.name
    );

    return (
      <li className='navBar__item' key={element.name}>
        {isSocialLink ? (
          <a
            className='navBar__link'
            href={element.path}
            onClick={() => handleClick()}
          >
            {element.name}
          </a>
        ) : (
          <NavLink
            className='navBar__link'
            to={element.path}
            onClick={() => {
              handleClick();
              handleScroll();
            }}
          >
            {element.name}
          </NavLink>
        )}
      </li>
    );
  });

  return (
    <ul className='navBar__items'>
      {navElements}
      {<SocialsMobile />}
    </ul>
  );
};

export default MobileNavList;
